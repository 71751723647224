<template>
  <section class="medico__view">
    <div v-if="loading" class="circle-loader"></div>
    <!-- Main content -->
    <div class="content" v-if="medico">
      <b-row>
        <b-col sm="12" md="3">
          <!-- Imagem do médico -->
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <div class="text-center">
                <i class="fa fa-user-md fa-5x"></i>
                
              </div>

              <h3 class="profile-username text-center">
                  {{ medico.nome }}
              </h3>
              <p class="text-muted text-center">
                
                CRM {{ medico.crm }} {{  medico.uf }}
              </p>

              <div class="py-4">
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-stethoscope mr-1"></i> Especialidades </b>
                  </span>
                </p>
                <p class="clearfix">
                  <b-badge variant="outline-secondary" style="font-size: 1rem; padding: 7px 7px;"
                    v-for="especialidade in medico.medico_especialidades" :key="especialidade.id">
                      {{ especialidade.especialidade.nome }}
                      <span v-if="especialidade !== medico.medico_especialidades[medico.medico_especialidades.length - 1]"></span>
                    
                  </b-badge>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-male"></i> Idade</b>
                  </span>
                  <span class="float-right text-muted">
                    Indisponível
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-address-card-o"></i> CPF</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ medico.cpf }}
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-id-badge"></i> CNS</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ medico.cns }}
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-tag"></i> ID PIATTINO</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ medico.id }}
                  </span>
                </p>
                <!-- <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-tag"></i> ID PIATTINO</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ medico.municipio.nome }}
                  </span>
                </p> -->
                <!-- <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-map-marker mr-1"></i> Endereços</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ medico.medicoEnderecos.length }}
                  </span>
                </p> -->
              </div>

              <hr />

        
              
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </b-col>

        <b-col sm="12" md="9">
          <b-card no-body>
            <b-tabs pills fill card>
              <b-tab title="Informações de Contato" active>
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-phone mr-1"></i> Telefone/Celular
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <tr v-for="telefone in medico.medico_telefones" :key="telefone.id">
                              <td class="text-muted">
                                <span v-if="telefone.celular">
                                  <i class="fa fa-mobile mr-2"></i>
                                </span>
                                <span v-if="telefone.telefone">
                                  {{ telefone.telefone }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-envelope mr-1"></i> Emails
                          </h6>
                        </b-card-title>

                        <!-- <b-list-group flush>
                          <b-list-group-item
                            class="d-flex justify-content-between align-items-center"
                            v-for="email in contato.contato_emails"
                            :key="email.id"
                            variant="info"
                          >
                            <span>{{ email.email }}</span>
                            <b-badge v-if="email.valido" variant="success"
                              >Válido</b-badge
                            >
                            <b-badge v-else-if="email.valido === 0" variant="danger"
                              >Inválido</b-badge
                            >
                            <b-badge v-else variant="outline-dark"
                              >Validação Pendente</b-badge
                            >
                          </b-list-group-item>
                        </b-list-group> -->

                        <b-list-group flush>
                          <div
                            v-for="email in medico.medico_emails"
                            :key="email.id"
                            variant="info"
                          >
                            <span class="text-muted">{{ email.email }}</span>

                            
                            <a
                              :href="`mailto:${email.email}`"
                              v-b-tooltip.hover
                              title="Enviar email"
                              class="btn btn-link"
                            >
                              <i class="mdi mdi-email-outline"></i>
                            </a>
                          </div>
                        </b-list-group>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-map-marker mr-1"></i> Endereço
                          </h6>
                        </b-card-title>

                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div
                              v-for="endereco in medico.medico_enderecos"
                              :key="endereco.id"
                            >
                              <div class="text-muted">
                                {{ endereco.endereco }}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-instagram mr-1"></i> Redes Sociais
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div
                             
                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Dados Profissionais" active>
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-hospital-o mr-2"></i> Área de Atuação
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <tr v-for="area_atuacao in medico.medico_area_atuacaos" :key="area_atuacao.id">
                              <td class="text-muted">
                                <i class="fa  fa-medkit mr-2"></i>
                                {{ area_atuacao.area_atuacao.nome }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa  fa-thumb-tack mr-1"></i> Data de inscrição
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div>
                              <div class="text-muted">
                                {{ medico.dt_inscricao }}
                              </div>
                              <div class="text-muted">
                              Estado: {{ medico.dt_inscricao_uf }}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-12">
                      <b-card no-body class="w-100">
                        <b-card-header>
                          <h6 class="mb-0">
                            <i class="fa fa-hospital-o mr-2"></i> Local de Atuação
                          </h6>
                        </b-card-header>
                        <b-card-body class="p-0">
                          <div class="table-responsive">
                            <table class="table table-bordered mb-0">
                              <thead>
                                <tr>
                                  <th>Razão Social</th>
                                  <th>Hospitais</th>
                                  <th>Endereços</th>
                                  <th>CEPs</th>
                                  <th>Números</th>
                                  <th>CNPJ</th>
                                  <th>CNES</th>
                                  <th>Emails</th>
                                  <th>Telefones</th>
                                  <th>Diretor CPF</th>
                                  <th>URL</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="estabelecimento in medico.estabelecimento_medicos" :key="estabelecimento.id">
                                  <td><i class="fa fa-medkit mr-2"></i>{{ estabelecimento.estabelecimento.razao_social }}</td>
                                  <td>{{ estabelecimento.estabelecimento.nome_fantasia }}</td>
                                  <td>{{ estabelecimento.estabelecimento.logradouro }}, {{ estabelecimento.estabelecimento.complemento }}, {{ estabelecimento.estabelecimento.bairro }} -{{ estabelecimento.estabelecimento.uf }} </td>
                                  <td>{{ estabelecimento.estabelecimento.cep }}</td>
                                  <td>{{ estabelecimento.estabelecimento.numero }}</td>
                                  <td>{{ estabelecimento.estabelecimento.cnpj }}</td>
                                  <td>{{ estabelecimento.estabelecimento.cnes }}</td>
                                  <td>{{ estabelecimento.estabelecimento.email }}</td>
                                  <td>{{ estabelecimento.estabelecimento.telefone }}</td>
                                  <td>{{ estabelecimento.estabelecimento.diretor_cpf }}</td>
                                  <td>{{ estabelecimento.estabelecimento.url }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-body>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-plus-square"></i> CBO
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div
                              v-for="cbo in medico.estabelecimento_medicos"
                              :key="cbo.id"
                            >
                              <div class="text-muted">
                                {{ cbo.cbo}}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o"></i> Estado de emissão do CRM
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                          
                              <div class="text-muted">
                                {{ medico.municipio && medico.municipio.nome ? medico.municipio.nome : 'Não disponível' }}
                              </div>
                          
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o"></i> Cargo/Função
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                          
                              <div class="text-muted">
                                Indisponível
                              </div>
                          
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o"></i> Atende plano de saúde
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                          
                              <div class="text-muted">
                                Indisponível
                              </div>
                          
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o"></i> Valor médio da consulta
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                          
                              <div class="text-muted">
                                Indisponível
                              </div>
                          
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Informações de Licenciamento">
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Residente
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div
                              
                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Certificações Adicionais
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div
                              
                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Cursos e Treinamentos
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div
                              
                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                    </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Detalhes Adicionais">
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Data de Nascimento
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Gênero
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Idiomas Falados
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Preferências de Contato
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Horários de Atendimento
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Disponibilidade para Parcerias/Colaborações
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Interações com a Indústria">
                <b-card-body>
                    <b-row>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Publicações Científicas
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Histórico de Participação em Estudos/Ensaios Clínicos
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Participação em Eventos/Conferências Médicas
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Interesse em Amostras de Medicamentos
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Preferências por Tipos de Medicamentos
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Indústrias atendidas
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                      <div class="col-lg-6 grid-margin stretch-card">
                        <b-card>
                          <b-card-title>
                            <h6 class="mb-0">
                              <i class="fa fa-pencil-square-o mr-1"></i> Última interação
                            </h6>
                          </b-card-title>
                          <table class="table">
                            <tbody class="contato__view__color--white">
                              <div

                              >
                                <div class="text-muted">
                                  Indisponível
                                </div>
                              </div>
                            </tbody>
                          </table>
                        </b-card>
                      </div>
                    </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Informações sobre Prescrições">
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Medicamentos mais Prescritos
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Categorias Terapêuticas de Interesse
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Feedback sobre Medicamentos
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Categoria do médico
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Médico influenciador
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Marketing e Comunicação">
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Laboratórios com relacionamento
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Laboratórios sem relacionamento
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Consentimento para Recebimento de Marketing PIATTINO
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Histórico de Interações com Campanhas de Marketing
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Preferências de Recebimento de Informações
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Informações de Consumo">
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Time favorito
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Casado
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Marca de smartphone favorita
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Marca de computador favorita
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Marca de carro favorita
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Modelo de carro favorito
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Companhia aérea favorita
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Instituição de ensino favorita
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Informações de Segurança">
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Histórico de Reclamações/Incidentes
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Medidas de Conformidade
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Notas e Comentários">
                <b-card-body>
                  <b-row>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Observações Adicionais
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                    <div class="col-lg-6 grid-margin stretch-card">
                      <b-card>
                        <b-card-title>
                          <h6 class="mb-0">
                            <i class="fa fa-pencil-square-o mr-1"></i> Comentários sobre Interações
                          </h6>
                        </b-card-title>
                        <table class="table">
                          <tbody class="contato__view__color--white">
                            <div

                            >
                              <div class="text-muted">
                                Indisponível
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </b-card>
                    </div>
                  </b-row>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import MedicoService from "@/services/doktor/medico.service";

export default {
  name: "MedicoView",
  props: ["id"],
  data() {
    return {
      medico: null,
      loading: false,
      especialidadesFields: [
        {
          key: "especialidade",
          label: "Especialidade",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      this.medico = null;
      this.loading = true;
      MedicoService.get(this.id)
        .then((response) => {
          this.medico = response.data;
          if (!this.medico.municipio) {
            this.medico.municipio = { nome: null };
          }

        })
        .catch((error) => {
          console.error("Error fetching medico data:", error);
          // Handle the error appropriately
        })
        .finally(() => {
          this.loading = false;
          
        });
    },
  },

  
};
</script>
<style>
.fa-mobile {
    font-size: 30px; /* Ajuste o tamanho conforme necessário */
}
.table-responsive {
  margin-bottom: 0;
}

.table {
  width: 100%;
  max-width: 100%;
}

.table th,
.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>